import { conditionalToLowerCase } from '@aurora/shared-client/components/seo/useSeoProperties';
import useIsomorphicRedirect, {
  RedirectStatusCode
} from '@aurora/shared-client/components/useIsomorphicRedirect';
import type {
  BoardPages,
  BoardPagesAndParams,
  MessageEditPages,
  MessagePages,
  MessagePagesAndParams,
  MessagePostPages,
  MessageReplyPages,
  MessageReplyPagesAndParams
} from '@aurora/shared-client/routes/endUserRoutes';
import type { CustomRouter, RouteWithQuery } from '@aurora/shared-client/routes/useCustomRouter';
import useEndUserRoutes from '@aurora/shared-client/routes/useEndUserRoutes';
import type { EndUserQueryParams } from '@aurora/shared-types/pages/enums';
import { EndUserPages, EndUserPathParams } from '@aurora/shared-types/pages/enums';
import { PathParamValidationType } from '@aurora/shared-types/redirects/pageRedirect';
import UrlHelper from '@aurora/shared-utils/helpers/urls/UrlHelper/UrlHelper';
import ConversationStyleBehaviorHelper from '../helpers/boards/ConversationStyleBehaviorHelper';
import useEndUserPageDescriptor from './useEndUserPageDescriptor';
import type { PageAccessData } from './usePageAccess';
import { useIsPreviewMode } from './messages/useCurrentOrPreviewMessage';
import useAppContextProviderRevisionMessage from './context/AppContext/AppContextProvider/useAppContextProviderRevisionMessage';

/**
 * Returns whether the provided page matches the current page name
 *
 * @param pageRoute the end user page
 * @param router the end user router
 */
function isValidPath<PageT extends EndUserPages>(
  pageRoute: PageT,
  router: CustomRouter<PageT, EndUserQueryParams>
): boolean {
  const { getCurrentPageName } = router;

  return pageRoute === getCurrentPageName();
}

/**
 * Returns whether the category id from the path params matches the category id from the context message's parent
 *
 * @param categoryDisplayId the display id of the parent category
 * @param router the end user router
 */
function isValidParentCategory(
  categoryDisplayId: string,
  router: CustomRouter<EndUserPages, EndUserQueryParams>
): boolean {
  const { getPathParam } = router;
  const categoryDisplayIdFromUrl: string = getPathParam<BoardPagesAndParams>(
    EndUserPathParams.CATEGORY_ID
  );

  return categoryDisplayId.toLowerCase() === categoryDisplayIdFromUrl.toLowerCase();
}

/**
 * Returns whether the board id from the path params matches the board id from the context message's parent
 *
 * @param boardDisplayId the display id of the parent board
 * @param router the end user router
 */
function isValidParentBoard(
  boardDisplayId: string,
  router: CustomRouter<EndUserPages, EndUserQueryParams>
): boolean {
  const { getPathParam } = router;
  const boardDisplayIdFromUrl: string = getPathParam<MessagePagesAndParams>(
    EndUserPathParams.BOARD_ID
  );

  return boardDisplayId.toLowerCase() === boardDisplayIdFromUrl.toLowerCase();
}

/**
 * Returns whether the message subject from the path params matches the message subject from the context message
 *
 * @param messageSubject the message subject
 * @param router the end user router
 */
function isValidMessageSubject(
  messageSubject: string,
  router: CustomRouter<EndUserPages, EndUserQueryParams>
): boolean {
  const { getPathParam } = router;
  const messageSubjectFromUrl: string = getPathParam<MessagePagesAndParams>(
    EndUserPathParams.MESSAGE_SUBJECT
  );

  return messageSubject.toLowerCase() === messageSubjectFromUrl.toLowerCase();
}

/**
 * Board pages will redirect under two conditions:
 *
 * 1) The board does exist but the parent category id supplied in the URL is incorrect
 * 2) The path for the board type is incorrect (e.g. /kb instead of /discussions)
 *
 * @param categoryDisplayId the display id of the parent category
 * @param boardRoute the board route
 * @param router the end user router
 */
export function isValidBoardPageUrl(
  categoryDisplayId: string,
  boardRoute: BoardPages,
  router: CustomRouter<EndUserPages, EndUserQueryParams>
): boolean {
  return isValidPath(boardRoute, router) && isValidParentCategory(categoryDisplayId, router);
}

/**
 * Post pages will redirect under two conditions:
 *
 * 1) The category id supplied in the URL is incorrect
 * 2) The path for the board type is incorrect (e.g. /kb instead of /discussions)
 *
 * @param categoryDisplayId the display id of the parent category
 * @param postPageRoute the message post page route
 * @param router the end user router
 */
export function isValidPostPageUrl(
  categoryDisplayId: string,
  postPageRoute: MessagePostPages,
  router: CustomRouter<EndUserPages, EndUserQueryParams>
): boolean {
  return isValidPath(postPageRoute, router) && isValidParentCategory(categoryDisplayId, router);
}

/**
 * Edit pages will redirect under three conditions:
 *
 * 1) The category id supplied in the URL is incorrect
 * 2) The message subject supplied in the URL is incorrect
 * 3) The path for the board type is incorrect (e.g. /kb instead of /discussions)
 *
 * @param boardDisplayId the display id of the parent board
 * @param messageSubject the message subject
 * @param editPageRoute the message edit page route
 * @param router the end user router
 */
export function isValidEditPageUrl(
  boardDisplayId: string,
  messageSubject: string,
  editPageRoute: MessageEditPages,
  router: CustomRouter<EndUserPages, EndUserQueryParams>
): boolean {
  return (
    isValidPath(editPageRoute, router) &&
    isValidParentBoard(boardDisplayId, router) &&
    isValidMessageSubject(messageSubject, router)
  );
}

/**
 * Message pages will redirect under two conditions
 *
 * 1) The message does exist but the parent board id OR the message subject OR the message id supplied in the URL is incorrect
 * 2) The path for the message type is incorrect (e.g. /blog instead of /kb)
 *
 * @param boardDisplayId the display id of the parent board
 * @param messageSubject the message subject
 * @param messagePage the message page route
 * @param router the end user router
 */
export function isValidMessagePageUrl(
  boardDisplayId: string,
  messageSubject: string,
  messagePage: MessagePages,
  router: CustomRouter<EndUserPages, EndUserQueryParams>
): boolean {
  return (
    isValidPath(messagePage, router) &&
    isValidParentBoard(boardDisplayId, router) &&
    isValidMessageSubject(messageSubject, router)
  );
}

/**
 * Message Reply pages will redirect under three conditions
 *
 * 1) The root message id and the reply id in the URL are the same
 * 2) The reply does exist but the parent board id OR the root message ID or the message subject supplied in the URL is incorrect
 * 3) The path for the message reply type is incorrect (e.g. /blog instead of /kb)
 *
 * @param isReplyIdRootMessageId whether the message reply id and the root message id are the same
 * @param boardDisplayId the display id of the parent board
 * @param messageSubject the message subject
 * @param rootMessageUid the root message uid
 * @param replyPage the message reply page route
 * @param router the end user router
 */
export function isValidMessageReplyPageUrl(
  isReplyIdRootMessageId: boolean,
  boardDisplayId: string,
  messageSubject: string,
  rootMessageUid: string,
  replyPage: MessageReplyPages,
  router: CustomRouter<EndUserPages, EndUserQueryParams>
): boolean {
  if (isReplyIdRootMessageId) {
    return false;
  }
  const { getPathParam } = router;
  const messageIdFromUrl: string = getPathParam<MessageReplyPagesAndParams>(
    EndUserPathParams.MESSAGE_ID
  );

  return (
    isValidPath(replyPage, router) &&
    isValidParentBoard(boardDisplayId, router) &&
    isValidMessageSubject(messageSubject, router) &&
    rootMessageUid.toLowerCase() === messageIdFromUrl.toLowerCase()
  );
}

/**
 * Redirects based off of URL formatting and the pageObject type specified for a given page
 *
 * @param pageParamData the required data from the AppContextProvider
 */
export default function usePageParamValidation(pageParamData: PageAccessData) {
  const {
    redirectBehavior: { pathParamValidationType }
  } = useEndUserPageDescriptor();
  const { router } = useEndUserRoutes();
  const isPreviewMode = useIsPreviewMode();

  let shouldRedirect: boolean = false;
  let redirectRoute: RouteWithQuery<EndUserPages, EndUserQueryParams> = {
    route: EndUserPages.CommunityPage,
    params: {}
  };

  const { loading: revisionLoading, data: latestRevisionMessage } =
    useAppContextProviderRevisionMessage(pageParamData);

  if (pathParamValidationType !== PathParamValidationType.NONE) {
    const {
      appContextQueryResult: { data: appContextData, loading: appContextLoading },
      contextNodeQueryResult: { data: urlNodeData, loading: urlNodeLoading },
      contextMessageQueryResult: { data: urlMessageData, loading: urlMessageLoading }
    } = pageParamData;

    const latestMessage = isPreviewMode ? latestRevisionMessage : urlMessageData?.message;
    const isRequiredContextNodeRedirectReady: boolean =
      !appContextLoading && !urlNodeLoading && !!urlNodeData?.coreNode;
    const isRequiredContextMessageRedirectReady: boolean =
      !appContextLoading && !urlMessageLoading && !!urlMessageData?.message;
    const isRequiredRevisionMessageRedirectReady: boolean = !revisionLoading && !!latestMessage;
    const isUsingSeoLowercasePath: boolean =
      appContextData?.community?.seoProperties?.lowercasePath;

    switch (pathParamValidationType) {
      case PathParamValidationType.BOARD: {
        if (isRequiredContextNodeRedirectReady && 'conversationStyle' in urlNodeData.coreNode) {
          const { coreNode } = urlNodeData;
          const { parent, displayId: boardDisplayId } = coreNode;
          const { displayId: categoryDisplayId } = parent;
          const { boardRoute } = ConversationStyleBehaviorHelper.getInstance(coreNode);

          // redirect users hitting board pages with invalid URLs for boards that do exist
          if (!isValidBoardPageUrl(categoryDisplayId, boardRoute, router)) {
            redirectRoute = {
              route: boardRoute,
              params: {
                boardId: conditionalToLowerCase(boardDisplayId, isUsingSeoLowercasePath),
                categoryId: conditionalToLowerCase(categoryDisplayId, isUsingSeoLowercasePath)
              }
            };
            shouldRedirect = true;
          }
        }
        break;
      }

      case PathParamValidationType.POST: {
        if (isRequiredContextNodeRedirectReady && 'conversationStyle' in urlNodeData.coreNode) {
          const { coreNode } = urlNodeData;
          const { parent, displayId: boardDisplayId } = coreNode;
          const { displayId: categoryDisplayId } = parent;
          const { messagePostPage } = ConversationStyleBehaviorHelper.getInstance(coreNode);

          // redirect users hitting board pages with invalid URLs for boards that do exist
          if (!isValidPostPageUrl(categoryDisplayId, messagePostPage, router)) {
            redirectRoute = {
              route: messagePostPage,
              params: {
                boardId: conditionalToLowerCase(boardDisplayId, isUsingSeoLowercasePath),
                categoryId: conditionalToLowerCase(categoryDisplayId, isUsingSeoLowercasePath)
              }
            };
            shouldRedirect = true;
          }
        }
        break;
      }

      case PathParamValidationType.EDIT: {
        if (isRequiredContextMessageRedirectReady && 'board' in urlMessageData.message) {
          const { message } = urlMessageData;
          const messageSubject: string = UrlHelper.determineSlugForMessagePath(message);
          const { board, uid } = message;
          const { displayId: boardDisplayId } = board;
          const { messageEditPage } = ConversationStyleBehaviorHelper.getInstance(board);

          // redirect users hitting message edit pages with invalid URLs for boards that do exist
          if (!isValidEditPageUrl(boardDisplayId, messageSubject, messageEditPage, router)) {
            redirectRoute = {
              route: messageEditPage,
              params: {
                boardId: conditionalToLowerCase(boardDisplayId, isUsingSeoLowercasePath),
                messageSubject,
                messageId: uid
              }
            };
            shouldRedirect = true;
          }
        }
        break;
      }

      case PathParamValidationType.MESSAGE: {
        if (isRequiredRevisionMessageRedirectReady && 'board' in latestMessage) {
          const messageSubject: string = UrlHelper.determineSlugForMessagePath(latestMessage);
          const { board, uid } = latestMessage;
          const { displayId: boardDisplayId } = board;

          const { messagePage } = ConversationStyleBehaviorHelper.getInstance(board);

          // redirect users hitting message pages with invalid URLs for message that do exist
          if (!isValidMessagePageUrl(boardDisplayId, messageSubject, messagePage, router)) {
            redirectRoute = {
              route: messagePage,
              params: {
                boardId: conditionalToLowerCase(boardDisplayId, isUsingSeoLowercasePath),
                messageSubject,
                messageId: uid
              }
            };
            shouldRedirect = true;
          }
        }
        break;
      }

      case PathParamValidationType.REPLY: {
        if (isRequiredContextMessageRedirectReady && 'board' in urlMessageData.message) {
          const { message } = urlMessageData;
          const messageSubject: string = UrlHelper.determineSlugForMessagePath(message);
          const { board, conversation } = message;
          const { displayId: boardDisplayId } = board;
          const {
            topic: { uid: rootMessageUid }
          } = conversation;

          const { messageReplyPage, messagePage } =
            ConversationStyleBehaviorHelper.getInstance(board);
          const replyId: string = router.getPathParam<MessageReplyPagesAndParams>(
            EndUserPathParams.REPLY_ID
          );
          const isReplyIdRootMessageId: boolean = +replyId === rootMessageUid;

          // redirect users hitting message reply pages with invalid URLs for replies that do exist
          if (
            !isValidMessageReplyPageUrl(
              isReplyIdRootMessageId,
              boardDisplayId,
              messageSubject,
              String(rootMessageUid),
              messageReplyPage,
              router
            )
          ) {
            redirectRoute = {
              route: !isReplyIdRootMessageId ? messageReplyPage : messagePage,
              params: {
                boardId: conditionalToLowerCase(boardDisplayId, isUsingSeoLowercasePath),
                messageSubject,
                messageId: rootMessageUid,
                ...(!isReplyIdRootMessageId && { replyId })
              }
            };
            shouldRedirect = true;
          }
        }
        break;
      }
      default: {
        break;
      }
    }
  }

  useIsomorphicRedirect(shouldRedirect, redirectRoute, RedirectStatusCode.MOVED_PERMANENTLY);
}
